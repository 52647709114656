import React from "react";
import { connect } from "react-redux";
import _data from "../../data";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="position-relative bg-image pt-100 h-full" image-overlay="10">
            <div className="background-image-wraper"></div>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-10 col-lg-6">
                        <div className="section-heading text-white py-5">
                            <h1 className="text-white font-weight-bold">Cosmos: A Space Aventure</h1>
                            <p></p>
                            <div className="action-btns mt-4">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="https://apps.apple.com/app/1608214793" className="d-flex align-items-center app-download-btn btn btn-white btn-rounded">
                                            <span className="fab fa-apple icon-size-sm mr-3 color-primary"></span>
                                            <div className="download-text text-left">
                                                <small>Download form</small>
                                                <h5 className="mb-0">App Store</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://play.google.com/store/apps/details?id=com.frezapp.cosmos&hl=en_US&gl=US" className="d-flex align-items-center app-download-btn btn btn-white btn-rounded">
                                            <span className="fab fa-google-play icon-size-sm mr-3 color-primary"></span>
                                            <div className="download-text text-left">
                                                <small>Download form</small>
                                                <h5 className="mb-0">Google Play</h5>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-5">
                        <div className="img-wrap">
                             <img src="assets/img/app-mobile-image.png" alt="cosmos"  height="500"/>
                        </div>
                    </div>
                </div>

            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
