import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// importing all the themes
/* import Theme1 from "../themes/theme1";
import Theme2 from "../themes/theme2";
import Theme3 from "../themes/theme3";
import Theme4 from "../themes/theme4"; */
import Theme5 from "../themes/theme5";
/* import Theme6 from "../themes/theme6";
import Theme7 from "../themes/theme7";
import Theme8 from "../themes/theme8";
import Theme9 from "../themes/theme9";
import Login from "../themes/login";
import SignUp from "../themes/signup";
import ResetPassword from "../themes/resetPassword";
import Faq from "../themes/faq";
import NotFound from "../themes/404";
import ComingSoon from "../themes/comingSoon";
import ThankYou from "../themes/thankYou";
import Team from "../themes/team";
import SingleTeam from "../themes/singleTeam";
import BlogDefault from "../themes/blogDefault";
import BlogNoSidebar from "../themes/blogNoSidebar";
import BlogLeftSidebar from "../themes/blogLeftSidebar";
import BlogRightSidebar from "../themes/blogRightSidebar";
import BlogSingleLeftSidebar from "../themes/blogSingleLeftSidebar";
import BlogSingleRightSidebar from "../themes/blogSingleRightSidebar"; */
import AboutUs from "../themes/aboutUs";
import ContactUs from "../themes/contactUs";

export default class RoutesX extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Routes>
            <Route exact path="/" element={<Theme5/>} />
            {/* <Route exact path="/theme1" element={<Theme1/>} />
            <Route exact path="/theme2" element={<Theme2/>} />
            <Route exact path="/theme3" element={<Theme3/>} />
            <Route exact path="/theme4" element={<Theme4/>} />
            <Route exact path="/theme5" element={<Theme5/>} />
            <Route exact path="/theme6" element={<Theme6/>} />
            <Route exact path="/theme7" element={<Theme7/>} />
            <Route exact path="/theme8" element={<Theme8/>} />
            <Route exact path="/theme9" element={<Theme9/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/signup" element={<SignUp/>} />
            <Route exact path="/resetPassword" element={<ResetPassword/>} />
            <Route exact path="/faq" element={<Faq/>} />
            <Route exact path="/404" element={<NotFound/>} />
            <Route exact path="/comingSoon" element={<ComingSoon/>} />
            <Route exact path="/thankYou" element={<ThankYou/>} />
            <Route exact path="/team" element={<Team/>} />
            <Route exact path="/singleTeam" element={<SingleTeam/>} />
            <Route exact path="/blogDefault" element={<BlogDefault/>} />
            <Route exact path="/blogNoSidebar" element={<BlogNoSidebar/>} />
            <Route exact path="/blogLeftSidebar" element={<BlogLeftSidebar/>} />
            <Route exact path="/blogRightSidebar" element={<BlogRightSidebar/>} />
            <Route exact path="/blogSingleLeftSidebar" element={<BlogSingleLeftSidebar/>} />
            <Route exact path="/blogSingleRightSidebar" element={<BlogSingleRightSidebar/>} /> */}
            <Route exact path="/privacy-policy" element={<AboutUs/>} />
            <Route exact path="/contact" element={<ContactUs/>} />
          </Routes>
        </Router>
      </React.Fragment>
    );
  }
}
