import React, { Component } from "react";

import Header from "../components/Header/header";
import Hero from "../components/HeroSection/HeroSectionInnerPage";
/* import Breadcrumb from "../components/Breadcrumb";
import AboutUs from "../components/AboutUs/AboutUs2";
import Download from "../components/CallToAction/DownloadExt"; */
import WorkProcess from "../components/WorkProcess";
/* import TeamMember from "../components/TeamMember";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany"; */
import FooterSection from "../components/Footer";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Hero  />

        <div className="main ptb-60 ">
        <WorkProcess/>

        </div>
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default Theme;
