import React from "react";
import { connect } from "react-redux";

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
              <div className="container">
                  <a className="navbar-brand" href="/">
                    <h1 className="text-white font-weight-bold mt-2">Frezapp</h1>
                  </a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="ti-menu"></span>
                  </button>

                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav ml-auto menu">
                          <li><a href="/" className="page-scroll">Home</a></li>
                          <li><a href="/contact" className="page-scroll">Contact</a></li>
                      </ul>
                  </div>
              </div>
          </nav>
      </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);
